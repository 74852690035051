import React from "react"
import Layout from "../components/layout"

import * as styles from "./karriere.module.scss";
import { StaticImage } from "gatsby-plugin-image"

const BuchhaltungPage = () => {

    return <Layout apply={"Zahlentalent mit Leidenschaft für Buchhaltung"}>

        <div className={styles.carrerPage}>


            <div
                className={styles.introImage}>
                <StaticImage
                    src="../images/schnitzel.jpg"
                    width={500}
                    quality={85}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Schnitzel" />

                <p>Handel & Verkauf von Lebensmittel,<br /> Herstellung von Schnitzel & Cordon Bleu</p>

            </div>

            <div>
                <h1>Zahlentalent (m/w/d) mit Leidenschaft für Buchhaltung</h1>
                <h3>38,5 Stunden</h3>
            </div>


            <h2>Aufgabengebiet</h2>

            <ul>
                <li>Sie verfügen über eine abgeschlossene kaufmännische Ausbildung sowie die Buchhalterprüfung oder Sie haben eine Handelsakademie (oder eine berufsbildene Schule höhere Schule mit kaufmännischen Schwerpunkt) abgeschlossen</li>
                <li>Idealerweise bringen Sie bereits Berufserfahrung in der selbstständigen Durchführung der laufenden Buchhaltung mit</li>
                <li>Die MS Office Programme (vor allem Excel) sind Ihnen vertraut und Sie haben ein analytisches und logisches Denkvermögen</li>
                <li>Eine positive und selbstständige Persönlichkeit mit hohem Lernwillen und einer genauen Arbeitsweise zeichnen Sie aus (gerne auch Wiedereinstieger*innen)</li>
            </ul>

            <h2>Anforderungen</h2>
            <ul>
                <li>Ausgezeichnete Deutsch-Kenntnisse in Wort und Schrift</li>
                <li>gute Englischkenntnisse in Wort und Schrift</li>
                <li>Teamfähigkeit</li>
                <li>Zuverlässigkeit</li>
                <li>Selbstständige Arbeitsweise mit rascher Auffassungsgabe und Eigenverantwortung</li>
            </ul>

            <h2>Wir bieten</h2>

            <ul>
                <li>Ein <b>familiäres Arbeitsklima</b> in einem innovativen Vorzeigebetrieb</li>
                <li>Die <b>Sicherheit</b> einer langfristigen Anstellung in einem wachsenden Unternehmen</li>


                <li className={"mt-5"}>Stunden nach Vereinbarung</li>
                <li>Montag - Freitag: 09:00 - 12:00 Uhr und 14:30 - 17:00 Uhr</li>
                <li>Samstag: 09:00 - 12:00 Uhr</li>
                <li>Vollzeit und auch Teilzeit möglich</li>

            </ul>

            <p className={styles.gehalt + " mt-5"}>
                Angaben des Unternehmens gemäß Gleichbehandlungsgesetz: EUR 2.500,- brutto
                auf Basis Vollzeit/38,5 Wochenstunden.
            </p>

            <p className={styles.gehaltAdd + " mt-2"}>
                Eine Überzahlung ist möglich und abhängig von Ihrer bisherigen Berufserfahrung und Qualifikation.
            </p>

        </div>

    </Layout>

}

export default BuchhaltungPage;
